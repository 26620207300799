<template>
  <div id="courseOutside">
    <div style="margin: 0 auto 94px">
      <TopOutside />
    </div>
    <div class="main">
      <div class="myCourses">
        我的课程
        <span style="color: #666666">({{ courseList.length || 0 }})</span>
      </div>
      <div class="courseList">
        <ul>
          <li
            v-for="(item, index) in courseList"
            :key="index"
            @click="chapter(item)"
          >
            <img :src="item.imgVertical" class="imgVertical" />
            <div>
              <div class="names">{{ item.name }}</div>
              <div class="professorName">
                <img class="proImg" src="@/assets/Web/proImg.png" />
                <span>{{ item.professorName }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <FooterOutside />
  </div>
</template>

<script>
import TopOutside from "@/views/Web/TopOutside.vue";
import FooterOutside from "@/views/Web/FooterOutside.vue";
import { getCourseOutside } from "@/api/Web.js";
import "./web.less";
export default {
  name: "courseOutside",
  data() {
    return {
      page: 1,
      size: 1000,
      total: 0,
      courseList: [],
    };
  },
  components: {
    TopOutside,
    FooterOutside,
  },
  created() {
    this.courseOutside();
  },
  methods: {
    chapter(val) {
      this.$router.push({ path: `/chapterOutside`, query: val });
    },
    handChange(val) {
      this.page = val;
      this.courseOutside();
    },
    async courseOutside() {
      const res = await getCourseOutside({ page: this.page, size: this.size });
      const { data, success } = res.data;
      if (success) {
        const { records } = data;
        this.courseList = records && records.length ? records : [];
        this.total = data.total;
      } else {
        this.courseList = [];
        this.total = 0;
      }
    },
  },
};
</script>

<style lang='less' scoped>
#courseOutside {
  background: #ffffff;
  color: #333333;

  .main {
    width: 1200px;
    margin: 0 auto;
    .myCourses {
      font-size: 18px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      margin: 21px 0 3px;
    }
    .courseList {
      min-height: 88vh;
      overflow: auto;
    }
  }
}
</style>
